
import axiosClient from '@/lib/rest/axiosClient';
import assetsModule from '@/store/modules/assetsModule';
import gptModule from '@/store/modules/gptModule';
import { getComponent, sleep } from '@/utils/helpers';
import { Debounce } from 'vue-debounce-decorator';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import GptMixin from '@/components/gpt/GptMixin';

@Component({
  components: {
    Dropdown: () => getComponent('common/Dropdown'),
  },
})
export default class TasqAIAudioEntry  extends mixins(GptMixin) {
  @Prop({ type: String, required: false, default: '' }) typeOfData?: any;

  selectedAudioSegment: any = null;

  audioSegments: any = [];

  mounted() {
    this.selectedAudioTab = 'B-FARM LD 18-392HC';
    this.selectedAudioSegment = this.audioSegments[0];
  }

  get enabledWells() {
    return assetsModule.enabledWells;
  }

  get enabledWellsMapping() {
    return assetsModule.enabledWells.map((well) => {
      return well.text;
    });
  }




  // AUDIO CODE

  isRecording = false;
  transcript = '';
  recognition: any = null;
  selectedAudioTab = '';
  showAudioSegment = true;
  isSpeaking = false;

  startRecording() {
    if (this.isRecording) return;

    try {
      // Initialize SpeechRecognition
      // @ts-ignore
      this.recognition = new (window.SpeechRecognition ||
      // @ts-ignore
        window.webkitSpeechRecognition ||
        // @ts-ignore
        window.mozSpeechRecognition ||
        // @ts-ignore
        window.msSpeechRecognition)();

      console.log('SpeechRecognition initialized:', this.recognition);

      this.recognition.lang = 'en-US';
      this.recognition.continuous = true;
      this.recognition.interimResults = false;

      this.recognition.onresult = (event) => {
        console.log('onresult event:', event);
        for (let i = event.resultIndex; i < event.results.length; ++i) {
          if (event.results[i].isFinal) {
            this.transcript += event.results[i][0].transcript + ' ';
            console.log('Transcript updated:', this.transcript);
          }
        }
      };

      this.recognition.onerror = (event) => {
        console.error('Speech recognition error detected:', event.error);
      };

      this.recognition.onend = () => {
        console.log(
          'Speech recognition ended. Is recording:',
          this.isRecording
        );
        if (this.isRecording) {
          this.recognition!.start();
          console.log('Restarting recognition');
        } else {
          this.isRecording = false;
        }
      };

      this.recognition.start();
      this.isRecording = true;
      console.log('Speech recognition started');
    } catch (error) {
      console.error('Error initializing speech recognition:', error);
    }
  }

  stopRecording() {
    if (!this.isRecording) return;

    this.isRecording = false;
    this.recognition!.stop();
  }

  closePopup() {
    this.stopRecording();
    this.$emit('close');
  }

  isLoadingSegments = false;


  async getLastActions() {
    try {

      let operator =  this.operatorDetails['operatorName']

      if( operator === 'demo'){
          operator = 'pdce'
      }

      const { data } = await axiosClient.get('/meta/latest-actions', {
        params: {
          operator: operator,
        },
      });
      gptModule.setLastestWellsLastActions(data);
    } catch (error) {}
  }

  async submitAudioEntry() {

    console.log(gptModule.selectedAiGroupWellsObj.filter(s => s.typeOfData === this.typeOfData));
    console.log(this.enabledWells);
    const wells  = gptModule.selectedAiGroupWellsObj.filter(s => s.typeOfData === this.typeOfData)
    const operatorDetails: any = this.operatorList.find(
      (o) => o['appName'] === this.operatorName
    );

    const results = await Promise.all(
      [...wells.map(resource =>
        axiosClient.post('/ai/comments', {
          "body": this.transcript,
          "wellMetaData": {
              "nodeid": resource.wellName,
              "level": "well",
              ...(this.operatorName !== 'demo' && {
          operator_name: operatorDetails['operatorName'],
        }),
        ...(this.operatorName !== 'demo' && {
          operator_id: operatorDetails['operatorID'],
        }),
          }
      })
      )]
    );
    gptModule.removeByTypeSelectedAiGroupWellsObj(this.typeOfData)
    this.$tasqAlert({
      title: 'Success',
      message: 'Last Action Entry Submitted',
      type: 'success',
    });
    this.stopRecording();
    await sleep(2500)
    this.getLastActions()
    this.$emit('close');
  }
}
